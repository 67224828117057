const sounds = [
  new Audio("/gonk1.wav"),
  new Audio("/gonk2.wav"),
  new Audio("/gonk3.wav"),
];

function App() {
  function gonk() {
    const sound = sounds[Math.floor(Math.random() * sounds.length)];
    (sound!.cloneNode() as HTMLAudioElement).play();
  }
  return (
    <button className="gonk-button" onClick={gonk}>
      GONK
    </button>
  );
}

export default App;
